import { fetchBrands } from "../../functions/fetch";

function BrandCarousel(params) {
    const brands = fetchBrands();

    return (
        <div className="flex flex-col px-3 md:px-12 py-[10px] md:py-10 xl:px-[180px] gap-[5px]">
            <div className="flex px-[10px] items-center justify-between">
                <h4 className="block md:hidden font-secondary text-sm not-italic font-semibold leading-5 text-black-lidabro">
                    Brands
                </h4>
                <h4 className="hidden md:block font-secondary text-lg not-italic font-medium leading-7 text-black-lidabro">
                    Browse by Brands
                </h4>
            </div>
            <div className="flex gap-[16px] xl:gap-8 overflow-x-auto scrollbar-none">
                {brands.map((brand) => (
                    <div
                        key={brand.id}
                        className="min-w-[150px] min-h-[70px] cursor-pointer"
                        onClick={() => alert("clicked!")}
                    >
                        <img
                            className="min-h-[70px] max-h-[70px] w-auto"
                            src={brand.image}
                            alt={brand.name}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BrandCarousel;
