import { EyeSVG } from "../../images/SVGAssets";

function StreamSeeLiveHelp({ eyezon }) {
    return (
        <>
            <div className="flex flex-col items-center justify-center px-6 py-10 gap-6 rounded-lg bg-lightgray bg-center bg-cover bg-no-repeat bg-[url('https://via.placeholder.com/1500x1500')]">
                <div className="flex flex-col gap-2 font-secondary text-sm font-medium text-black-lidabro-select-text text-center">
                    <p>
                        Not sure what to choose? Let our professional seller
                        guide you through our catalog and help you find exactly
                        what you need.
                    </p>
                    <p>Join our live stream now!</p>
                </div>
                <button
                    data-eyezon={eyezon.id}
                    data-eyezon-target={eyezon.target}
                    data-eyezon-title={eyezon.title}
                    data-eyezon-put-in-cart
                    className="flex items-center justify-center gap-[10px] py-4 px-[22px] rounded-lg lidabro-gradient-eyezone lidabro-button-shadow text-white font-secondary text-base font-semibold"
                >
                    <EyeSVG fill="white" />
                    Join Stream with Seller
                </button>
            </div>
        </>
    );
}

export default StreamSeeLiveHelp;
