import { useNavigate } from "react-router-dom";

function SuggestionsItemSport({ suggestion }) {
    const navigate = useNavigate();

    return (
        <div
            className="flex rounded-[15px] bg-white px-3 py-1 cursor-pointer"
            onClick={() => navigate(suggestion.url)}
        >
            <p className="font-secondary text-sm not-italic font-medium leading-5 text-gray-lidabro-text text-nowrap">
                {suggestion.name}
            </p>
        </div>
    );
}

export default SuggestionsItemSport;
