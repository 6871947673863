import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../hook/useAuth";
import { RequireAuth } from "./RequireAuth";
import Main from "../pages/Main/Main";
import Demo from "../pages/Main/Demo";
import SellerSignUpPage from "../pages/Account/Seller/SellerSignUpPage";
import SellerSignInPage from "../pages/Account/Seller/SellerSignInPage";
import SellerCreateShop from "../pages/Account/Seller/SellerCreateShop";
import {
    SellerCreateShopStep1,
    createShopStep1Action,
} from "../components/Seller/SellerCreateShopStep1";
import SellerCreateShopStep2 from "../components/Seller/SellerCreateShopStep2";
import SellerCreateShopStep3 from "../components/Seller/SellerCreateShopStep3";
import SellerCreateShopStep4 from "../components/Seller/SellerCreateShopStep4";
import SellerCreateShopStep5 from "../components/Seller/SellerCreateShopStep5";
import SellerHome from "../pages/Account/Seller/SellerHome";
import SellerProfile from "../layout/SellerProfile";
import SellerYourShop from "../pages/Account/Seller/SellerYourShop";
import SellerYourCatalog from "../pages/Account/Seller/SellerYourCatalog";
import SellerOrders from "../pages/Account/Seller/SellerOrders";
import ErrorPage from "../pages/Error/Error";
import Container from "./Container";
import SellerYourCatalogAdd from "../pages/Account/Seller/SellerYourCatalogAdd";
import SellerYourCatalogAddPage2 from "../pages/Account/Seller/SellerYourCatalogAddPage2";
import SportContainer from "../layout/SportContainer";
import Sport from "../pages/Main/Sport";
import ItemsSport from "../pages/Items/ItemsSport";
import ItemSport from "../pages/Items/ItemSport";
import BasketSport from "../components/Basket/BasketSport";
import { fetchItemById, fetchItemByArticle } from "../functions/fetch";

const Routes = () => {
    const { token } = useAuth();

    const router = createBrowserRouter([
        {
            path: "/",
            loader() {
                return { token: token };
            },
            element: <Container />,
            children: [
                {
                    index: true,
                    element: <Demo />,
                },
                {
                    path: "/sport",
                    element: <SportContainer />,
                    children: [
                        {
                            index: true,
                            element: <Sport />,
                        },
                        {
                            path: ":subdepartment",
                            element: <ItemsSport />,
                            loader: ({ params }) => {
                                const { subdepartment } = params;
                                return { subdepartment };
                            },
                        },
                        {
                            path: ":subdepartment/:category",
                            element: <ItemsSport />,
                            loader: ({ params }) => {
                                const { subdepartment, category } = params;
                                return { subdepartment, category };
                            },
                        },
                        {
                            path: ":subdepartment/:category/:subcategory",
                            element: <ItemsSport />,
                            loader: ({ params }) => {
                                const { subdepartment, category, subcategory } =
                                    params;
                                return { subdepartment, category, subcategory };
                            },
                        },
                        {
                            path: "items/:item",
                            element: <ItemSport />,
                        },
                    ],
                },
                {
                    path: "/become-a-seller",
                    element: <SellerSignUpPage />,
                },
                {
                    path: "/seller-signin",
                    element: <SellerSignInPage />,
                },
                {
                    path: "/",
                    element: <RequireAuth />,
                    children: [
                        {
                            path: "create-shop",
                            element: <SellerCreateShop />,
                            children: [
                                {
                                    path: "step-1",
                                    element: <SellerCreateShopStep1 />,
                                    action: createShopStep1Action,
                                },
                                // {
                                //   path: "step-2",
                                //   element: <SellerCreateShopStep2 />,
                                //   action: createShopStep2Action
                                // },
                                // {
                                //   path: "step-3",
                                //   element: <SellerCreateShopStep3 />,
                                //   action: createShopStep3Action
                                // },
                                // {
                                //   path: "step-4",
                                //   element: <SellerCreateShopStep4 />,
                                //   action: createShopStep4Action
                                // },
                                // {
                                //   path: "step-5",
                                //   element: <SellerCreateShopStep5 />,
                                //   action: createShopStep5Action
                                // },
                            ],
                        },
                    ],
                },
                {
                    path: "profile",
                    element: <SellerProfile />,
                    children: [
                        {
                            path: "home",
                            element: <SellerHome />,
                        },
                        {
                            path: "your-shop",
                            element: <SellerYourShop />,
                        },
                        {
                            path: "your-catalog",
                            element: <SellerYourCatalog />,
                        },
                        {
                            path: "orders",
                            element: <SellerOrders />,
                        },
                    ],
                },
                {
                    path: "profile/your-catalog/add",
                    element: <SellerYourCatalogAdd />,
                },
                {
                    path: "profile/your-catalog/add/page-2",
                    element: <SellerYourCatalogAddPage2 />,
                },
            ],
        },
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router} />;
};

export default Routes;
