import React from "react";

function ControlWithIconSport({
    name,
    icon,
    action = () => {
        console.log(`Clicked ${name}`);
    },
}) {
    return (
        <div
            className="flex flex-col items-center cursor-pointer"
            onClick={action}
        >
            {icon}
            <p className="font-secondary text-xs not-italic font-medium leading-[18px] text-white">
                {name}
            </p>
        </div>
    );
}

export default ControlWithIconSport;
