import HeaderEmptySeller from '../../../components/Header/HeaderEmptySeller'
import NavigateBack from '../../../components/Controls/Navigation/NavigateBack';
import SellerCatalogAddItemCategory from '../../../components/Seller/SellerCatalogAddItemCategory';
import SellerCatalogAddItemInfo from '../../../components/Seller/SellerCatalogAddItemInfo';
import SellerCatalogAddItemInfoStreamer from '../../../components/Seller/SellerCatalogAddItemInfoStreamer';
import SellerCatalogAddItemInfoAdditional from '../../../components/Seller/SellerCatalogAddItemInfoAdditional';
import SellerCatalogAddItemDelivery from '../../../components/Seller/SellerCatalogAddItemDelivery';
import BannerWithAction from '../../../components/Banners/BannerWithAction';
import ButtonBanner from '../../../components/Controls/Buttons/ButtonBanner';
import { StarsSVG } from '../../../images/SVGAssets';

function SellerYourCatalogAddPage2(props){
    props = {
        category: 'Name of Category',
        subcategory: 'Name of Sub-Category',
        rate: '0'
    }
    
    return (
        <>
            <HeaderEmptySeller />
            <div className='flex flex-col items-center bg-gray-lidabro-seller'>
                <NavigateBack name={"Adding a new item"} callback={()=>{console.log("Navigation Back Clicked")}} bgColor={"bg-gray-lidabro-button"}/>
                <div className='flex flex-col w-9/12 gap-8'>
                    <BannerWithAction   icon={<StarsSVG/>} 
                                        text={"We have prefilled the product card with the help of AI"} 
                                        additionalText={"Does it help you?"} 
                                        buttonsArray={
                                            [<ButtonBanner label={"I like it!"} bgColor={"bg-white"} borderColor={"border-gray-lidabro-border-2"} labelColor={"text-gray-lidabro-text"} callback={()=>{console.log("Clicked 'I Like It'")}} key={"like"}/>,
                                            <ButtonBanner label={"Erase"} bgColor={"bg-blue-lidabro-button"} borderColor={"border-blue-lidabro-button"} labelColor={"text-white"} callback={()=>{console.log("Clicked 'Erase'")}} key={"erase"}/>
                                        ]} 
                                        bgColor={"bg-[#004EEB]"} 
                                        borderColor={"border-[#1570EF]"} 
                                        textColor={"text-white"} 
                                        additionalTextColor={"text-[#B2CCFF]"}/>
                    <SellerCatalogAddItemCategory props={props} />
                    <div className='flex gap-6'>
                        <div className='flex flex-col gap-6 w-1/2'>
                            <SellerCatalogAddItemInfo />
                            <SellerCatalogAddItemInfoStreamer />
                        </div>
                        <div className='flex flex-col gap-6 w-1/2'>
                            <SellerCatalogAddItemInfoAdditional />
                            <SellerCatalogAddItemDelivery />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SellerYourCatalogAddPage2;