import { useEffect, useState } from "react";
import BannerSport from "../../components/Banner/BannerSport";
import Subcategory from "../../components/Category/Subcategory";
import SportItemsCarousel from "../../components/ItemsCarousel/SportItemsCarousel";
import CuratedCollection from "../../components/CuratedCollection/CuratedCollection";
import BrandCarousel from "../../components/Carousel/BrandCarousel";
import {
    fetchSportNewItems,
    fetchSportRelatedItems,
} from "../../functions/fetch";

function Sport(params) {
    const [newItems, setNewItems] = useState([]);
    const [relatedItems, setRelatedItems] = useState([]);

    useEffect(() => {
        const fetchItems = async () => {
            const fetchedNewItems = await fetchSportNewItems();
            const fetchedRelatedItems = await fetchSportRelatedItems();
            setNewItems(fetchedNewItems);
            setRelatedItems(fetchedRelatedItems);
        };

        fetchItems();
    }, []);

    return (
        <>
            <BannerSport />
            <Subcategory path={window.location.pathname} />
            <div className="w-full flex px-3 py-[10px] sm:px-12 sm:py-[5px] md:px-12 md:py-10 lg:px-[60px] xl:px-[180px] border-b border-gray-lidabro-border-2">
                <SportItemsCarousel title={"New Comings"} items={newItems} />
            </div>
            <CuratedCollection />
            <BrandCarousel />
            <div className="w-full flex px-3 py-[10px] sm:px-12 sm:py-[5px] md:px-12 md:py-10 lg:px-[60px] xl:px-[180px] border-b border-gray-lidabro-border-2">
                <SportItemsCarousel
                    title={"Related Products"}
                    items={relatedItems}
                />
            </div>
        </>
    );
}

export default Sport;
