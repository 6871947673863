import { useEffect, useState, useRef } from "react";
import { ArrowLeft2SVG, ArrowRight2SVG } from "../../images/SVGAssets";
import ItemCardSport from "../ItemCard/ItemCardSport";
import { useIsOverflow } from "../../hook/useIsOverflow";

function SportItemsCarousel(params) {
    const scrollContainerRef = useRef(null);
    const isOverflow = useIsOverflow(scrollContainerRef);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: -160,
                behavior: "smooth",
            });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: 160,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {}, [params.items]);

    if (params.items.length === 0) {
        return (
            <div className="flex flex-col w-full">
                <div className="flex px-[10px] md:px-0 items-center justify-between">
                    <h4 className="font-secondary text-sm md:text-lg not-italic font-semibold md:font-medium leading-5 md:leading-7 text-black-lidabro">
                        {params.title}
                    </h4>
                    {isOverflow && (
                        <div className="flex items-center">
                            <div
                                className="flex px-3 py-[10px] items-center cursor-pointer"
                                onClick={scrollLeft}
                            >
                                <ArrowLeft2SVG />
                            </div>
                            <div
                                className="flex px-3 py-[10px] items-center cursor-pointer"
                                onClick={scrollRight}
                            >
                                <ArrowRight2SVG />
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className="flex justify-between gap-[18px] overflow-x-auto scrollbar-none"
                    ref={scrollContainerRef}
                >
                    <ItemCardSport key={1} loading={true} />
                    <ItemCardSport key={2} loading={true} />
                    <ItemCardSport key={3} loading={true} />
                    <ItemCardSport key={4} loading={true} />
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col w-full">
            <div className="flex px-[10px] md:px-0 items-center justify-between">
                <h4 className="font-secondary text-sm md:text-lg not-italic font-semibold md:font-medium leading-5 md:leading-7 text-black-lidabro">
                    {params.title}
                </h4>
                {isOverflow && (
                    <div className="flex items-center">
                        <div
                            className="flex px-3 py-[10px] items-center cursor-pointer"
                            onClick={scrollLeft}
                        >
                            <ArrowLeft2SVG />
                        </div>
                        <div
                            className="flex px-3 py-[10px] items-center cursor-pointer"
                            onClick={scrollRight}
                        >
                            <ArrowRight2SVG />
                        </div>
                    </div>
                )}
            </div>
            <div
                className="flex justify-between gap-[18px] overflow-x-auto scrollbar-none"
                ref={scrollContainerRef}
            >
                {params.items.map((item) => (
                    <ItemCardSport key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
}

export default SportItemsCarousel;
