function ItemsListItem(params) {

    return (
        <div className="flex flex-col gap-2">
            <div className="min-w-[300px] min-h-[250px]">
                <img 
                    src={params.image}
                    alt={params.title} 
                />
            </div>
            <div className="flex flex-col font-secondary not-italic font-medium text-lg leading-7">
                <p className="text-xs leading-[18px] text-gray-lidabro-dark">{params.brand}</p>
                <p className="text-black-lidabro">{params.title}</p>
                <p className="text-blue-lidabro-header">₪ {params.price}</p>
            </div>
        </div>
    )
}

export default ItemsListItem;