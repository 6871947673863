import LocationSport from "./LocationSport";
import SuggestionsListSport from "./SuggestionsListSport";
import AboutUs from "./AboutUs";

function NavBarSport(params) {
    return (
        <div className="hidden md:flex px-6 py-2 items-center gap-6 border-t border-b border-gray-lidabro-border-2 bg-gray-lidabro-border-3">
            <LocationSport />
            <SuggestionsListSport />
            <AboutUs />
        </div>
    );
}

export default NavBarSport;
