import BasketListItem from "./BasketListItem";

function BasketListItems(params) {
    return (
        <>
            <div className="flex flex-col">
                {params.items.map((item, index) => (
                    <BasketListItem
                        key={index}
                        item={item.item}
                        quantity={item.quantity}
                    />
                ))}
            </div>
        </>
    );
}

export default BasketListItems;
