import { useState } from "react";
import InputOnly from "../SellerCatalog/InputOnly";
import SingleChoiceInput from "../SellerCatalog/SingleChoiceInput";
import SingleChoice from "../SellerCatalog/SingleChoice";
import MultipleChoiceInput from "../SellerCatalog/MultipleChoiceInput";
import MultipleChoice from "../SellerCatalog/MultipleChoice";
import ListCheckbox from "../List/ListCheckbox";


function SellerCatalogAddItemDelivery(params) {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    
    return (

        <div className='w-full flex flex-col gap-6 bg-white p-6 rounded-lg'>
            <div className="flex items-center justify-between">
                <label htmlFor="price" className="font-secondary text-base font-semibold w-1/3">Who will deliver the item(s)<span className="text-red-lidabro-important">*</span></label>
                <div className="flex items-end gap-6 rounded-lg px-3 py-2.5">
                    <ListCheckbox label={"My Shop"} />
                    <ListCheckbox label={"Lidabro"} />
                </div>
            </div>
            <div className="flex items-start justify-between">
                <label htmlFor="price" className="font-secondary text-base font-semibold w-1/3">Delivery range<span className="text-red-lidabro-important">*</span></label>
                <div className="w-2/3 flex flex-col items-end gap-6 rounded-lg px-3 py-2.5">
                    <SingleChoice atributeName={"delivery_pickup"} placeholder={"Choose Pick Up Location"} />
                    <SingleChoice atributeName={"delivery_pickup"} placeholder={"Select Delivery Range"} />
                </div>
            </div>
            <div className="flex items-start justify-between">
                <label htmlFor="price" className="font-secondary text-base font-semibold w-1/6">Delivery kind<span className="text-red-lidabro-important">*</span></label>
                <div className="flex items-end gap-6 rounded-lg px-3 py-2.5">
                    <div className="flex flex-col gap-6">
                        <ListCheckbox label={"All"} />
                        <ListCheckbox label={"Pick Up in Shop"} />
                    </div>
                    <div className="flex flex-col gap-6">
                        <ListCheckbox label={"Fast (within 24 Hours)"} />
                        <ListCheckbox label={"Standart (within 2-3 Hours)"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SellerCatalogAddItemDelivery;