import { ChevronRight2SVG } from "../../images/SVGAssets";

function MobileMenu(params) {
    const categories = [
        {
            department: "Sports Apparel",
            subdepartments: [
                "Men's Athletic Clothing",
                "Women's Clothing",
                "Accessoirise",
            ],
        },
        {
            department: "Sports Supplements",
            subdepartments: [
                "Proteins, Gainers",
                "Sport Vitamins",
                "Amino Acids",
                "Creatines",
                "Fitness Nutrition",
            ],
        },
    ];
    return (
        <>
            <div className="w-full flex flex-col px-6 py-10">
                <div className="flex flex-col gap-12">
                    {categories.map((category) => {
                        return (
                            <div className="flex flex-col font-secondary text-sm font-medium text-gray-lidabro-text">
                                <p className="text-lg px-[10px] py-[9px]">
                                    {category.department}
                                </p>
                                {category.subdepartments.map(
                                    (subdepartment) => {
                                        return (
                                            <div className="flex items-center gap-3 px-[10px] py-[9px]">
                                                <p className="flex-grow">
                                                    {subdepartment}
                                                </p>
                                                <div>
                                                    <ChevronRight2SVG />
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default MobileMenu;
