import ButtonWithIconRight from "../Controls/ButtonWithIconRight";
import { ArrowRight3SVG } from "../../images/SVGAssets";

function CuratedCollectionItem(params) {
    return (
        <div className="flex flex-col p-[10px] min-w-[300px] max-w-[300px] min-h-[300px] max-h-[300px]">
            <div className="flex grow">
                <img
                    className="w-full h-[230px] object-cover object-[30%_70%] filter grayscale hover:grayscale-0 transition ease duration-300"
                    src={params.image}
                    alt={params.title}
                />
            </div>
            <div className="flex justify-between py-[13px]">
                <p className="font-secondary text-sm not-italic font-semibold leading-5 text-black">
                    {params.title}
                </p>
                <ButtonWithIconRight
                    name={"Shop Now"}
                    icon={<ArrowRight3SVG />}
                />
            </div>
        </div>
    );
}

export default CuratedCollectionItem;
