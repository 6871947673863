import { EyeSVG, DotSVG } from "../../images/SVGAssets";

function StreamSeeLive({ eyezon, title }) {
    //TODO: 1.Availability
    //TODO: 2.Streamer Time
    console.log(title);
    return (
        <>
            <div className="flex flex-col p-4 gap-4 rounded-lg bg-white">
                {/* <button
                    data-eyezon={eyezon ? eyezon.id : undefined}
                    data-eyezon-title={eyezon ? eyezon.title : undefined}
                    className={`flex items-center justify-center gap-[10px] py-4 px-[22px] rounded-lg lidabro-gradient-eyezone lidabro-button-shadow text-white font-secondary text-lg font-semibold ${
                        eyezon ? "" : "opacity-50 cursor-not-allowed"
                    }`}
                    disabled={!eyezon}
                > */}
                <button
                    data-eyezon="232"
                    data-eyezon-title={title}
                    className={`flex items-center justify-center gap-[10px] py-4 px-[22px] rounded-lg lidabro-gradient-eyezone lidabro-button-shadow text-white font-secondary text-lg font-semibold`}
                >
                    <EyeSVG fill="white" />
                    See live
                </button>
                <div className="flex gap-2 items-center font-secondary text-sm font-medium text-gray-lidabro-select-remove">
                    <div className="flex gap-1 items-center">
                        <DotSVG />
                        <p className="text-[#47CD89]">Available</p>
                    </div>
                    <p className="text-center overflow-x-auto text-nowrap">
                        Sa-Mo 9:00 AM - 6:00 PM Jerusalem Time
                    </p>
                </div>
            </div>
        </>
    );
}

export default StreamSeeLive;
