import React, { useEffect, useState } from "react";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import NavBar from "../../components/NavBar/NavBar";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ItemInfo from "../../components/ItemInfo/ItemInfo";
import ItemDetails from "../../components/ItemDetails/ItemDetails";
import ItemsSimilar from "../../components/ItemsSimilar/ItemsSimilar";



function Demo() {
    const path = ['Home', 'Electronics', 'Household', 'Grills', 'Tefal Optigrill']

    return (
    <>
        <Header />
        <NavBar />
        <Breadcrumbs path={path}/>
        <ItemInfo />
        <ItemDetails />
        <ItemsSimilar key={'01'} category={'grills'} />
        <ItemsSimilar key={'02'} category={'household'} />
        <Footer />
    </>
        
    );
}

export default Demo;