import { useState, useEffect } from "react";
import BasketListItems from "./BasketListItems";
import InputFieldDashed from "../Inputs/InputFieldDashed";
import BasketSubtotal from "./BasketSubtotal";
import SportItemsCarousel from "../ItemsCarousel/SportItemsCarousel";
import StreamSeeLiveHelp from "../Stream/StreamSeeLiveHelp";
import ButtonIcon from "../Controls/ButtonIcon";
import { TrashSVG, ShareSVG, XClose2SVG } from "../../images/SVGAssets";
import {
    fetchItemById,
    fetchSportRelatedItems,
    fetchBasketBySession,
    fetchBasketItemsBySession,
    deleteBasket,
} from "../../functions/fetch";

function BasketSport(params) {
    //TODO: SkeletonUI
    const emptyBasket = { promocode: "" };
    const [basket, setBasket] = useState(emptyBasket);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBasket = async () => {
            const fetchedBasket = await fetchBasketBySession();
            const fetchedBasketItems = await fetchBasketItemsBySession();
            setBasket(fetchedBasket);
            setItems(fetchedBasketItems);
            setLoading(false);
        };

        fetchBasket();
    }, []);

    return (
        <>
            {loading ? (
                <div>Loading</div>
            ) : (
                <div className="flex flex-col w-full md:max-w-[650px]">
                    <div className="max-w-full hidden sm:flex items-center justify-between pt-4 pb-6 px-[45px] bg-white">
                        <p className="font-main text-2xl font-medium text-gray-lidabro-text">
                            Shopping Basket
                        </p>
                        <div className="flex items-center">
                            <ButtonIcon
                                icon={<TrashSVG />}
                                callback={() => {
                                    deleteBasket();
                                    setItems([]);
                                    setBasket(emptyBasket);
                                }}
                            />
                            <ButtonIcon icon={<ShareSVG />} />
                            <ButtonIcon
                                icon={<XClose2SVG />}
                                callback={params.closeBasket}
                            />
                        </div>
                    </div>

                    <div className="max-w-full flex flex-col gap-3 px-3 sm:px-[45px] pb-3 sm:pb-12 bg-white">
                        <div className="flex flex-col gap-3 sm:gap-6 rounded-[15px] sm:px-6 sm:py-6 sm:bg-gray-lidabro-select-background">
                            <BasketListItems items={items} />
                            <InputFieldDashed
                                id={"promocode"}
                                name={"promocode"}
                                placeholder={"Add Promocode"}
                                value={basket.promocode}
                            />
                            <div className="border-b border-gray-lidabro-border-3"></div>
                            <BasketSubtotal items={items} />
                            <button className="flex items-center justify-center px-[22px] py-4 rounded-lg bg-blue-lidabro-button md:bg-gray-lidabro-breadcrumb shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] font-secondary text-base font-semibold text-white text-opacity-90">
                                Place an order
                            </button>
                        </div>
                        <div className="flex flex-col sm:flex-col-reverse gap-6">
                            <div className="flex sm:hidden">
                                <SportItemsCarousel
                                    title={"You may like"}
                                    items={fetchSportRelatedItems()}
                                />
                            </div>
                            <div className="hidden sm:flex">
                                <SportItemsCarousel
                                    title={"Related Products"}
                                    items={fetchSportRelatedItems()}
                                />
                            </div>
                            <StreamSeeLiveHelp
                                eyezon={{ id: "1", target: "1", title: "1" }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default BasketSport;
