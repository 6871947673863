import { useState, useEffect } from "react";
import ItemsFilterOption from "./ItemsFilterOption";
import { ChevronUp2SVG, ChevronDown2SVG } from "../../images/SVGAssets";

function ItemsFilter(params) {
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

    function renderChevron(){
        return (
            <div>
                { dropdownIsOpen ? <ChevronUp2SVG /> : <ChevronDown2SVG /> }
            </div>
            
        )
    }

    function renderChildren(children){
        if(!children){
            return null;
        }
        
        return (
            <div className="flex flex-col px-[6px] py-1">
                {children.map((child) => (
                    <ItemsFilterOption 
                        key={child.name} 
                        label={child.name} 
                        status={false} />))}    
            </div>
        )
    }

    useEffect(()=>{}, [dropdownIsOpen]);

    return (
        <>
            <div 
                className="flex px-[10px] py-[9px] border-b border-gray-lidabro-border-3"
                onClick={()=>{setDropdownIsOpen(!dropdownIsOpen)}}>
                <div className="flex items-center mr-auto">
                    <p className="font-main text-sm text-black-lidabro ml-3" >{params.name}</p>
                </div>
                {params.children ? renderChevron() : null}
            </div>
            {dropdownIsOpen ? renderChildren(params.children) : null}
        </>
    )
}

export default ItemsFilter;