import { useState, useEffect } from "react";

function BasketSubtotal(params) {
    const discount = 1;
    const [priceOfGoods, setPriceOfGoods] = useState(0);
    const [subtotal, setSubtotal] = useState(0);

    function calcPriceOfProducts(items) {
        const amount = 1;
        const total = items.reduce(
            (accumulator, currentValue) =>
                accumulator + currentValue.item.actualPrice[0].price * amount,
            0
        );
        return total;
    }

    function calcSubtotal(items, discount = 0) {
        const subtotal = calcPriceOfProducts(items) - discount;
        if (subtotal < 0) {
            return 0;
        }
        return subtotal;
    }

    useEffect(() => {
        setSubtotal(calcSubtotal(params.items, discount));
        setPriceOfGoods(calcPriceOfProducts(params.items));
    });

    return (
        <>
            <div className="flex flex-col gap-3">
                <div className="flex justify-between">
                    <p className="font-secondary text-base font-medium text-gray-lidabro-text">
                        Subtotal ({params.items.length}{" "}
                        {params.items.length > 1 ? "items" : "item"})
                    </p>
                    <p className="font-secondary text-base font-medium text-gray-lidabro-text">
                        {subtotal} ₪
                    </p>
                </div>
                <div className="flex gap-3">
                    <p className="font-secondary text-xs font-medium leading-[18px] text-gray-lidabro-placeholder text-nowrap">
                        Price of products
                    </p>
                    <div className="flex grow border-b border-dashed border-gray-lidabro-border-2"></div>
                    <p className="font-secondary text-sm font-medium text-gray-lidabro-placeholder">
                        {priceOfGoods} ₪
                    </p>
                </div>
                <div className="flex gap-3">
                    <p className="font-secondary text-sm font-medium text-green-lidabro-text">
                        Discount
                    </p>
                    <div className="flex grow border-b border-dashed border-green-lidabro-text"></div>
                    <p className="font-secondary text-sm font-medium text-green-lidabro-text">
                        -{discount} ₪
                    </p>
                </div>
            </div>
        </>
    );
}

export default BasketSubtotal;
