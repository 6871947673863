function ProgressBar({ isAnimating, finished }) {
    return (
        <>
            <div className="h-[5px] w-full bg-gray-lidabro-border-3 relative overflow-hidden">
                <div
                    className={`h-full bg-blue-lidabro-border ${
                        finished ? "w-full" : "w-0"
                    } ${isAnimating ? "animate-fill-line" : ""}`}
                ></div>
            </div>
        </>
    );
}

export default ProgressBar;
