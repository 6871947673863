import { useState } from "react";
import Counter from "../Controls/Counter";
import ButtonIcon from "../Controls/ButtonIcon";
import { ShoppingBag2SVG } from "../../images/SVGAssets";
import { addItemToBasketByArticle } from "../../functions/fetch";

function ItemAddToBasker({ item }) {
    const [quantity, setQuantity] = useState(1);

    return (
        <div className="flex gap-4">
            <Counter counter={quantity} setCounter={setQuantity} />
            <div className="flex grow rounded-lg bg-gray-lidabro-breadcrumb">
                <ButtonIcon
                    icon={<ShoppingBag2SVG />}
                    callback={() => {
                        addItemToBasketByArticle(item, quantity);
                    }}
                />
            </div>
        </div>
    );
}

export default ItemAddToBasker;
