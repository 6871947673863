function ItemsFilterPrice(params) {

    return (
        <div className="flex flex-col gap-3 px-[9px] py-5">
            <p className="font-secondary text-sm not-italic font-medium leading-5 text-gray-lidabro-text">Price, ₪</p>
            <div className="flex gap-3">
                <input 
                    className="w-1/2 px-[14px] py-[10px] border border-gray-lidabro-border-2 rounded-lg bg-white"
                    placeholder={`From ${params.minPrice ? params.minPrice : 0}`}
                    type="text" />
                <input 
                    className="w-1/2 px-[14px] py-[10px] border border-gray-lidabro-border-2 rounded-lg bg-white"
                    placeholder={`Till ${params.maxPrice ? params.maxPrice : 9999}`}
                    type="text" />
            </div>
        </div>
    )
}

export default ItemsFilterPrice;