import { ChevronRightSVG } from "../../images/SVGAssets";

function BreadcrumbsSkeleton(params) {
    return (
        <>
            <div className="flex sm:hidden items-center gap-[16px] px-6 py-3 animate-pulse">
                <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
                <div className="w-1/2 h-6 bg-gray-300 rounded"></div>
            </div>

            <div className="hidden sm:flex p-6 gap-[16px] xmd:px-[60px] lg:px-[160px] md:py-6 animate-pulse">
                <div className="w-1/4 h-8 bg-gray-300 rounded"></div>
                <div className="flex items-center gap-2">
                    <div className="w-24 h-6 bg-gray-300 rounded"></div>
                    <ChevronRightSVG />
                    <div className="w-24 h-6 bg-gray-300 rounded"></div>
                    <ChevronRightSVG />
                    <div className="w-24 h-6 bg-gray-300 rounded"></div>
                </div>
            </div>
        </>
    );
}

export default BreadcrumbsSkeleton;
