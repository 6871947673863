import { FilterSVG, Sort2SVG } from "../../images/SVGAssets";

function ControlPanelSport(params) {

    return (
        <>
            <div className="flex items-center justify-between p-3">
                <div className="flex md:hidden items-center justify-center min-w-10 min-h-10">
                    <FilterSVG />
                </div>
                <div>
                    <p className="font-secondary text-base not-italic font-medium leading-6 text-gray-lidabro-dark">{params.amount > 1 ? `${params.amount} Products` : `${params.amount} Product`}</p>
                </div>
                <div className="flex items-center justify-center min-w-10 min-h-10">
                    <Sort2SVG />
                </div>
            </div>
        </>
    )
}

export default ControlPanelSport;