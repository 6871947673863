import SuggestionsItemSport from "./SuggestionsItemSport";
import { fetchSuggestions } from "../../functions/fetch";

function SuggestionsListSport(params) {
    let suggestions = fetchSuggestions();

    return (
        <div className="flex flex-1 gap-3 items-center overflow-x-auto scrollbar-none">
            {suggestions.length > 0
                ? suggestions.map((suggestion) => (
                      <SuggestionsItemSport
                          key={suggestion.key}
                          suggestion={suggestion}
                      />
                  ))
                : null}
        </div>
    );
}

export default SuggestionsListSport;
