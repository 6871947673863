import { useState, useLayoutEffect } from "react";

export const useIsOverflow = (ref, callback) => {
    const [isOverflow, setIsOverflow] = useState(undefined);

    useLayoutEffect(() => {
        const { current } = ref;

        const trigger = () => {
            if (current) {
                const hasOverflow = current.scrollWidth > current.clientWidth;
                setIsOverflow(hasOverflow);

                if (callback) {
                    callback(hasOverflow);
                }
            }
        };

        trigger();

        window.addEventListener("resize", trigger);

        return () => {
            window.removeEventListener("resize", trigger);
        };
    }, [callback, ref]);

    return isOverflow;
};
