import ItemsListItem from "./ItemsListItem";
import ItemCardSport from "../ItemCard/ItemCardSport";

function ItemsList(params) {
    return (
        <>
            <div className="flex md:hidden flex-wrap justify-center gap-3 px-3 pb-3">
                {params.items.map((item) => (
                    <ItemsListItem
                        key={item.id}
                        id={item.id}
                        price={item.price}
                        brand={item.brand}
                        title={item.title}
                        image={item.image}
                        department={item.department}
                        category={item.category}
                    />
                ))}
            </div>

            <div className="hidden md:flex flex-wrap gap-3 px-3 pb-3">
                {params.items.map((item) => (
                    <ItemCardSport
                        key={item.id}
                        id={item.id}
                        price={item.price}
                        brand={item.brand}
                        title={item.title}
                        image={item.image}
                        department={item.department}
                        category={item.category}
                    />
                ))}
            </div>
        </>
    );
}

export default ItemsList;
