import { useState } from "react";

function ImageCarousel(params) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    function setDirection(direction) {
        switch (direction) {
            case "left":
                return "flex-row";
            case "right":
                return "flex-row-reverse";
            case "top":
                return "flex-col";
            case "bottom":
                return "flex-col-reverse";
            default:
                return "flex-row";
        }
    }

    function setGroupDirection(direction) {
        switch (direction) {
            case "left":
            case "right":
                return "flex-col";
            case "top":
            case "bottom":
                return "flex-row";
            default:
                return "flex-col";
        }
    }

    return (
        <div
            className={`w-full flex overflow-auto grow max-h-[50vh] xmd:max-h-none gap-12 ${setDirection(
                params.direction
            )}`}
        >
            <div
                className={`max-h-[600px] min-w-[74px] flex overflow-auto scrollbar-none gap-4 ${setGroupDirection(
                    params.direction
                )}`}
            >
                {params.images.map((image, index) => (
                    <div
                        key={index}
                        className={`min-w-[74px] max-w-[74px] ${
                            currentIndex != index ? "opacity-40" : ""
                        }`}
                        onClick={() => goToSlide(index)}
                    >
                        <img
                            className="rounded-[5px] object-cover"
                            src={image.url}
                            alt={image.altText}
                        />
                    </div>
                ))}
            </div>
            <div className="flex grow">
                {params.images > 0 ? (
                    <img
                        className="min-w-full flex-shrink-0 object-scale-down object-center"
                        src={params.images[currentIndex].url}
                        alt={params.images[currentIndex].altText}
                    />
                ) : (
                    <img
                        className="min-w-full flex-shrink-0 object-scale-down object-center"
                        src={
                            "https://via.placeholder.com/1000x1000?text=No+image"
                        }
                        alt={"No image"}
                    />
                )}
            </div>
        </div>
    );
}

export default ImageCarousel;
