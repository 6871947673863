import { AtributeRequired } from "./SellerCatalogHelper";

function InputOnly({atributeName, label, placeholder="Search", required, tooltip, onChange}) {
    return (
        <div className="flex flex-col gap-1.5">
            <label htmlFor={atributeName} className="font-secondary text-base font-semibold">{label}{required ? " " : null}{required ? AtributeRequired() : null}</label>
            <div className="border border-gray-lidabro-divider rounded-lg px-3 py-2.5">
                <input  id={atributeName}
                        className="w-full outline-none placeholder:font-main placeholder:text-sm placeholder:font-medium"
                        type="text"
                        placeholder={placeholder}
                        onChange={onChange}
                />
            </div>
            { tooltip == null ? null : tooltip }
        </div>
    )
}

export default InputOnly;