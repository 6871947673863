import { useState, useEffect } from "react";
import ProgressBar from "../ProgressBar/ProgressBar";
import {
    StreamsLogoSVG,
    EyeSVG,
    CursorSVG,
    Eye5SVG,
} from "../../images/SVGAssets";

function StreamsSport() {
    const [isAnimating, setIsAnimating] = useState(false);
    const [currentContent, setCurrentContent] = useState(0);

    function getLogoContent(id) {
        switch (id) {
            case 0:
                return <StreamsLogoSVG />;
            case 1:
                return (
                    <div className="relative flex items-center justify-center gap-[12.5px] px-[27.5px] py-4 border-[2.5px] rounded-lg border-white border-opacity-30 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] text-white bg-[linear-gradient(278deg,_#00FFA3_0%,_#0128FF_102.13%)]">
                        <div className="fill-white">
                            <EyeSVG />
                        </div>
                        <p className="font-secondary text-lg font-semibold">
                            See live
                        </p>
                        <div className="absolute top-[50px] right-[-20px]">
                            <CursorSVG />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="relative flex flex-col items-center justify-center text-white">
                        <div>
                            <Eye5SVG />
                        </div>
                        <p className="font-secondary text-3xl leading-[46px] font-medium">
                            Streams
                        </p>
                        <div className="absolute top-[-20px] right-[5px] flex items-center justify-center w-10 h-10 rounded-full bg-white">
                            <p className="font-secondary text-xl leading-[74px] font-bold text-black">
                                1
                            </p>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <div></div>
                    </div>
                );
            default:
                break;
        }
    }

    function getContent(id) {
        switch (id) {
            case 0:
                return (
                    <>
                        <p className="font-main text-2xl font-medium text-center text-gray-lidabro-breadcrumb">
                            Welcome to Lidabro!
                        </p>
                        <p className="font-secondary text-base font-medium text-gray-lidabro-breadcrumb text-center">
                            Our platform offers live product demonstrations with
                            sellers and product experts.
                        </p>
                    </>
                );
            case 1:
                return (
                    <>
                        <p className="font-main text-2xl font-medium text-center text-gray-lidabro-breadcrumb">
                            Jump into Stream
                        </p>
                        <p className="font-secondary text-base font-medium text-gray-lidabro-breadcrumb text-center">
                            To get started, simply press{" "}
                            <span className="font-bold">"See Live"</span> or{" "}
                            <span className="font-bold">"Join Stream"</span>{" "}
                            below any product or category that interests you.
                        </p>
                    </>
                );
            case 2:
                return (
                    <>
                        <p className="font-main text-2xl font-medium text-center text-gray-lidabro-breadcrumb">
                            Access Streams
                        </p>
                        <p className="font-secondary text-base font-medium text-gray-lidabro-breadcrumb text-center">
                            Here, you can view your active streams, recordings,
                            and chats with sellers.
                        </p>
                    </>
                );
            case 3:
                return (
                    <>
                        <p className="font-main text-2xl font-medium text-center text-gray-lidabro-breadcrumb">
                            Subscribe and get Discount!
                        </p>
                        <p className="font-secondary text-base font-medium text-gray-lidabro-breadcrumb text-center">
                            Get notified of our official launch date and be the
                            first to receive exclusive offers!
                        </p>
                        <p className="font-secondary text-base font-medium text-gray-lidabro-breadcrumb text-center">
                            We promise not to spam:)
                        </p>
                        <input
                            className="border p-2 rounded-lg placeholder:text-center"
                            type="text"
                            placeholder="Enter your email"
                        />
                    </>
                );
            default:
                break;
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsAnimating(true);
            setTimeout(() => {
                setCurrentContent((prevContent) =>
                    prevContent < 3 ? prevContent + 1 : prevContent
                );
                setIsAnimating(false);
            }, 1000);
        }, 4000);

        return () => clearTimeout(timer);
    }, [currentContent]);

    return (
        <>
            <div className="w-1/2 h-[640px] flex flex-col max-w-[450px] border-[5px] rounded-[20px] border-white shadow-[0px_0px_8px_0px_rgba(0,0,0,0.08),_0px_0px_16px_0px_rgba(0,0,0,0.24)] bg-white">
                <div className="h-1/2 flex items-center justify-center bg-[linear-gradient(278deg,_rgba(0,255,163,0.75)_0%,_rgba(1,40,255,0.75)_102.13%)] rounded-t-[15px]">
                    <div className={`${isAnimating ? "" : ""}`}>
                        {getLogoContent(3)}
                    </div>
                </div>

                <div className="flex flex-grow flex-col justify-between px-6 pt-12 pb-6 rounded-b-[15px]">
                    <div
                        className={`flex flex-col gap-6 transition-transform duration-1000 ${
                            isAnimating ? "" : ""
                        }`}
                    >
                        {getContent(currentContent)}
                    </div>

                    <div className="grid grid-cols-4 gap-2 mt-4">
                        <ProgressBar
                            isAnimating={currentContent === 0}
                            finished={currentContent > 0}
                        />
                        <ProgressBar
                            isAnimating={currentContent === 1}
                            finished={currentContent > 1}
                        />
                        <ProgressBar
                            isAnimating={currentContent === 2}
                            finished={currentContent > 2}
                        />
                        <ProgressBar
                            isAnimating={currentContent === 3}
                            finished={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default StreamsSport;
