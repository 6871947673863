import React from "react";

function CategoryItem({category}){
    return (
        <div className="flex rounded-[15px] bg-white px-3 py-1">
            <p className="font-secondary text-sm not-italic font-medium leading-5 text-black-lidabro">{category}</p>
        </div>
    );
};

export default CategoryItem;