import Breadcrumb from "./Breadcrumb";
import { ChevronLeftSVG } from "../../images/SVGAssets";
import { fetchCategoryNameByPath } from "../../functions/fetch";

function Breadcrumbs(params) {
    const category = params.path[params.path.length - 1];

    return (
        <>
            <div className="flex sm:hidden items-center gap-[16px] px-6 xmd:px-[60px] lg:px-[160px] py-3 md:py-6">
                <div>
                    <ChevronLeftSVG />
                </div>
                {params.path.length > 0 ? (
                    <Breadcrumb
                        path={params.path[params.path.length - 1]}
                        last={true}
                    />
                ) : (
                    <Breadcrumb path={"Home"} last={true} />
                )}
            </div>

            <div className="hidden sm:flex p-6 gap-[16px]">
                <p className="font-main text-2xl not-italic font-medium leading-8 text-black-lidabro text-nowrap">
                    {category.name}
                </p>
                <div className="flex items-center gap-2 font-secondary text-sm font-medium text-gray-lidabro-breadcrumb">
                    {params.path.length > 0 ? (
                        params.path.map((element, index) => (
                            <Breadcrumb
                                key={index}
                                path={element}
                                last={params.path.length - 1 == index}
                            />
                        ))
                    ) : (
                        <Breadcrumb key={0} path={"Home"} last={true} />
                    )}
                </div>
            </div>
        </>
    );
}

export default Breadcrumbs;
