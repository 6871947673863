export function sentenceCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatKey(key) {
    return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}
