import { useState, useEffect } from "react";
import { Checkmark3SVG, Heart5SVG } from "../images/SVGAssets";
import { sentenceCase } from "../functions/common";

function useSuccessMessage({ type, duration = 3000 }) {
    const [isVisible, setIsVisible] = useState(false);
    const [item, setItem] = useState(null);

    const trigger = (newItem) => {
        setItem(newItem);
        setIsVisible(true);
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, duration);

        return () => clearTimeout(timer);
    };

    const getSuccessMessage = () => {
        if (!isVisible || !item) return null;

        let textColor, icon;
        switch (type) {
            case "basket":
                textColor = "text-blue-lidabro-button";
                icon = <Checkmark3SVG />;
                break;
            case "favorites":
                textColor = "text-red-lidabro-text";
                icon = <Heart5SVG />;
                break;
            default:
                break;
        }

        return (
            <div
                className={`fixed bottom-5 left-5 flex max-w-[300px] p-[10px] items-center gap-4 rounded-lg bg-white shadow-[0px_2px_8px_0px_rgba(0,0,0,0.10)] 
                transition-transform duration-500 ease-in-out ${
                    isVisible ? "translate-x-0" : "-translate-x-full"
                }`}
            >
                <div className="max-w-[50px] max-h-[50px]">
                    <img
                        className="rounded-[5px]"
                        src={item.image[0].imageUrl}
                        alt={item.title}
                    />
                </div>
                <div className="flex flex-col font-secondary text-xs font-semibold leading-[18px]">
                    <div className={`flex gap-1 items-center ${textColor}`}>
                        <div>{icon}</div>
                        <p>Added to {sentenceCase(type)}</p>
                    </div>
                    <p>{item.title}</p>
                </div>
            </div>
        );
    };

    return { getSuccessMessage, trigger };
}

export default useSuccessMessage;
