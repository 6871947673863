import ItemsFilterPrice from "./ItemsFilterPrice";
import ItemsFilter from "./ItemsFilter";
import { fetchFiltersByCategory } from "../../functions/fetch";

function ItemsFilters(params) {
    const filters = fetchFiltersByCategory(params.category);

    return (
        <>
            <div className="w-full flex flex-col px-3 border-t border-r border-gray-lidabro-border-3">
                <ItemsFilterPrice />
                {filters.map((filter) => (
                    <ItemsFilter name={filter.name} children={filters}/>
                ))}
            </div>
        </>
    )
}

export default ItemsFilters;