import { useState, useId, useEffect } from "react";
import { Checkmark2SVG } from "../../images/SVGAssets";


function ItemsFilterOption(params) {
    const [_status, setStatus] = useState(params.status);

    function handleClick(){
        setStatus(!_status);
    }

    function renderCheckbox(status){
        switch (status) {
            case true:
                return (
                    <div className="w-6 h-6 rounded-lg border border-violete-lidabro justify-center items-center inline-flex hover:border-blue-lidabro-header" onClick={handleClick}>
                        <div className="w-5 h-5 relative">
                            <div className="w-5 h-5 left-0 top-0 absolute bg-blue-lidabro-header rounded-md" />
                            <div className="w-3 h-3 left-[4px] top-[4px] absolute">
                                <Checkmark2SVG />
                            </div>
                        </div>
                    </div>
                )
            case false:
                return <div className="w-6 h-6 relative bg-white rounded-lg border border-gray-lidabro-divider hover:border-gray-lidabro-dark" onClick={handleClick} />
        }
    }

    return (
        <div className="flex items-center gap-2 p-[10px]">
            { renderCheckbox(_status) }
            { params.label == null ? null : 
                (<p className="font-secondary text-xs not-italic font-medium text-gray-lidabro-text">{params.label}</p>)
            }
        </div>
        
    )
}

export default ItemsFilterOption;