import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchItemByName } from "../../functions/fetch";
import { SearchSVG } from "../../images/SVGAssets";

function SearchBarSport({placeholderText}) {
	const [query, setQuery] = useState("");
	const [results, setResults] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		if (query) {
			const abortController = new AbortController();
			fetchItemByName(query, abortController.signal).then((movies) => {
				if (movies) {
					setResults(movies.results);
				}
			});
			return () => {
				abortController.abort();
			};
		}
	}, [query]);

	const handleSubmit = useCallback(
		async (name) => {
			const movie = await fetchItemByName(name);
			navigate(`/library/${movie.results[0].id}`);
		},
		[navigate]
	);

	return (
		<form
			className="w-full flex items-center h-11"
			onSubmit={(e) => {
				e.preventDefault();
				handleSubmit(query);
			}}>
			<div className="w-full flex items-center justify-center bg-black-lidabro-light rounded-lg border border-gray-lidabro-border-4 px-5">
				<SearchSVG />
				<input
					className="w-full h-11 p-2 bg-black-lidabro-light"
					type="search"
					name=""
					id="search"
					list="result"
					placeholder={placeholderText}
					autoComplete="off"
					value={query}
					onChange={(e) => {
						setQuery(e.currentTarget.value);
					}}
				/>
				<datalist id="result-movies">
					{results.map((result, index) => (
						<option value={result.title} key={index} />
					))}
				</datalist>
			</div>
			
		</form>
	);
}

export default SearchBarSport;
