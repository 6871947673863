import React from "react";

function ButtonWithIconRight({name, icon}){
    return (
        <div className="flex items-center pl-[10px] pr-2 py-[2px] rounded-full gap-1 cursor-pointer border border-gray-lidabro-border-3">
            <p className="font-secondary text-xs not-italic font-medium leading-[18px] text-gray-lidabro-text">{name}</p>
            {icon}
        </div>
    );
};

export default ButtonWithIconRight;