import { useNavigate } from "react-router-dom";
import { fetchPathByCategoryName } from "../../functions/fetch";
import { Heart3SVG } from "../../images/SVGAssets";
import useSuccessMessage from "../../hook/useSuccessMessage";

function ItemCardSport({ item, loading = false }) {
    const navigate = useNavigate();
    const { getSuccessMessage, trigger } = useSuccessMessage({
        type: "favorites",
    });

    const handleFavoriteClick = (e) => {
        e.stopPropagation();
        trigger(item);
    };

    if (loading) {
        return (
            <div className="flex flex-col min-w-[160px] h-[250px] relative cursor-default animate-pulse">
                <div className="h-[170px] bg-gray-300"></div>
                <div className="flex flex-col py-3">
                    <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
                    <div className="h-3 bg-gray-300 rounded w-full mb-1"></div>
                    <div className="h-3 bg-gray-300 rounded w-1/2"></div>
                </div>
                <div className="absolute top-[10px] right-[11px]">
                    <div className="h-6 w-6 bg-gray-300 rounded-full"></div>
                </div>
            </div>
        );
    }

    return (
        <div
            className="flex flex-col min-w-[160px] h-[250px] relative cursor-pointer"
            onClick={() => navigate(`/sport/items/${item.article}`)}
        >
            <div className="h-[170px]">
                {item.images.length > 0 ? (
                    <img className="h-full" src={item.images} alt={item.name} />
                ) : (
                    <img
                        className="h-full"
                        src="https://via.placeholder.com/160x170"
                        alt={item.name}
                    />
                )}
            </div>
            <div className="flex flex-col py-3">
                <p className="font-secondary text-sm not-italic font-semibold leading-5 text-blue-lidabro-header">
                    ₪ {item.price}
                </p>
                <p className="font-secondary text-xs not-italic font-medium leading-[18px] text-black-lidabro">
                    {item.name}
                </p>
                <p className="font-secondary text-xs not-italic font-medium leading-[18px] text-gray-lidabro-dark">
                    {item.type ? item.type : "NO TYPE"}
                </p>
            </div>
            <div
                className="flex flex-col absolute top-[10px] right-[11px]"
                onClick={handleFavoriteClick}
            >
                <Heart3SVG />
            </div>
            {getSuccessMessage()}
        </div>
    );
}

export default ItemCardSport;
