import { useState } from "react";
import Slider from "../Slider/Slider";

function BannerSport(params) {
    const banners = [
        {
            id: 1,
            imageUrl: "/images/banners/banner1.png",
            alt: "Banner 1",
        },
        {
            id: 2,
            imageUrl: "https://via.placeholder.com/800x300?text=Slide+2",
            alt: "Banner 2",
        },
        {
            id: 3,
            imageUrl: "https://via.placeholder.com/800x300?text=Slide+3",
            alt: "Banner 3",
        },
    ];

    return <Slider images={banners} />;
}

export default BannerSport;
