import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import BreadcrumbsSkeleton from "../../components/Breadcrumbs/BreadcrumbsSkeleton";
import ItemInfoSport from "../../components/ItemInfo/ItemInfoSport";
import SportItemsCarousel from "../../components/ItemsCarousel/SportItemsCarousel";
import {
    fetchSportRelatedItems,
    fetchItemByArticle,
} from "../../functions/fetch";

function ItemSport(params) {
    const { item } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedItem = await fetchItemByArticle(item);
                setData(fetchedItem);
            } catch (err) {
                setError("Error fetching the item");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [item]);

    return (
        <>
            {loading ? (
                <BreadcrumbsSkeleton />
            ) : (
                <>
                    <Breadcrumbs path={data.breadcrumb_path} />
                    <ItemInfoSport item={data} />
                    <SportItemsCarousel
                        title={"Related Products"}
                        items={fetchSportRelatedItems()}
                    />
                </>
            )}
        </>
    );
}

export default ItemSport;
