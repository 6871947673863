import { MapPin4SVG } from "../../images/SVGAssets";

function LocationSport(params) {

    return (
        <div className="flex px-3 py-[10px] items-center gap-[6px] rounded-lg">
            <MapPin4SVG />
            <p className="font-secondary text-sm not-italic font-medium leading-5 text-gray-lidabro-text">Israel</p>
        </div>
    )
}

export default LocationSport;