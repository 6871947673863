function MobileSearch(params) {
    const suggestions = [
        "Search Suggestion",
        "Search Suggestion",
        "Search Suggestion",
        "Search Suggestion",
    ];

    return (
        <>
            <div className="w-full flex flex-col justify-center gap-6 px-6 py-10">
                <input
                    className="px-[10px] py-[9px] outline-none border-b border-gray-lidabro-text"
                    placeholder="I want to buy"
                    type="text"
                />
                <div className="flex flex-col justify-center font-secondary text-sm font-medium text-gray-lidabro-text">
                    {suggestions.map((suggestion) => {
                        return (
                            <p className="px-[10px] py-[9px]">{suggestion}</p>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default MobileSearch;
