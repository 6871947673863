import ButtonIcon from "../Controls/ButtonIcon";
import { BasketSVG, BasketSVG3, HeartSVG } from "../../images/SVGAssets";
import {
    addItemToBasketByArticle,
    toggleItemToFavoriteByArticle,
} from "../../functions/fetch";

function FavoriteListItem({ item }) {
    console.log("Item favorite");

    console.log(item);

    return (
        <>
            {item && (
                <div className="flex items-center px-[10px] py-3 gap-3">
                    <div
                        callback={() => {
                            toggleItemToFavoriteByArticle(item);
                        }}
                    >
                        <HeartSVG />
                    </div>
                    <div className="flex w-[50px] sm:w-[100px] h-[50px] sm:h-[100px]">
                        {item.images.length > 0 ? (
                            <img
                                className="w-full object-cover"
                                src={item.images[0].imageUrl}
                                alt={item.images[0].alt}
                            />
                        ) : null}
                    </div>
                    <div className="w-full flex pl-3 py-3 gap-3 items-center">
                        <div className="flex flex-col py-3 gap-[6px] grow">
                            <div className="flex flex-col">
                                <p className="font-secondary text-xs font-medium leading-[18px] text-nowrap text-gray-lidabro-select-remove">
                                    {item.type}
                                </p>
                                <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap text-gray-lidabro-text">
                                    {item.name}
                                </p>
                                <p className="font-secondary text-xs font-semibold leading-[18px] text-nowrap">
                                    ₪ {item.actualPrice[0].price}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex sm:hidden items-center justify-center"
                        callback={() => {
                            addItemToBasketByArticle(item, 1);
                        }}
                    >
                        <BasketSVG />
                    </div>
                    <div
                        className="hidden sm:flex px-[22px] py-4 items-center justify-center rounded-lg bg-gray-lidabro-breadcrumb shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
                        callback={() => {
                            addItemToBasketByArticle(item, 1);
                        }}
                    >
                        <BasketSVG3 />
                    </div>
                </div>
            )}
        </>
    );
}

export default FavoriteListItem;
