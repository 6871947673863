function ButtonIcon({ icon, callback }) {
    return (
        <div
            className="w-full flex py-2 px-3 items-center justify-center cursor-pointer"
            onClick={callback}
        >
            {icon}
        </div>
    );
}

export default ButtonIcon;
