import { useState, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import NavMenuSport from "../components/NavMenu/NavMenuSport";
import NavBarSport from "../components/NavBar/NavBarSport";
import FooterSport from "../components/Footer/FooterSport";
import BasketSport from "../components/Basket/BasketSport";
import FavoriteSport from "../components/Favorite/FavoriteSport";
import StreamsSport from "../components/Streams/StreamsSport";
import FloatingIsland from "../components/FloatingIsland/FloatingIsland";
import MobileSearch from "../components/MobileComponents/MobileSearch";
import MobileMenu from "../components/MobileComponents/MobileMenu";
import useSuccessMessage from "../hook/useSuccessMessage";
import { fetchSession } from "../functions/fetch";

function SportContainer(params) {
    const [isBasketOpen, setIsBasketOpen] = useState(false);
    const [isFavoriteOpen, setIsFavoriteOpen] = useState(false);
    const [isStreamsOpen, setIsStreamsOpen] = useState(false);
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [navMenuHeight, setNavMenuHeight] = useState(0);
    const navMenuRef = useRef(null);
    const successMessage = useSuccessMessage({ type: "favorites" });

    const toggleBasket = () => {
        setIsFavoriteOpen(false);
        setIsStreamsOpen(false);
        setIsBasketOpen(!isBasketOpen);
    };

    const toggleFavorite = () => {
        setIsBasketOpen(false);
        setIsStreamsOpen(false);
        setIsFavoriteOpen(!isFavoriteOpen);
    };

    const toggleStreams = () => {
        setIsFavoriteOpen(false);
        setIsBasketOpen(false);
        setIsStreamsOpen(!isStreamsOpen);
    };

    const toggleMobileSearch = () => {
        setIsMobileSearchOpen(!isMobileSearchOpen);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        const sessionId = Cookies.get("session");
        if (!sessionId) {
            fetchSession()
                .then((newSession) => {
                    Cookies.set("session", newSession.data.uuid, {
                        expires: new Date(newSession.data.expiryAt),
                        secure: true,
                        sameSite: "None",
                        path: "/",
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        if (navMenuRef.current) {
            setNavMenuHeight(navMenuRef.current.offsetHeight);
        }
    }, []);

    return (
        <>
            <div ref={navMenuRef}>
                <NavMenuSport
                    actions={{
                        basket: toggleBasket,
                        favorite: toggleFavorite,
                        streams: toggleStreams,
                        mobileSearch: toggleMobileSearch,
                        mobileMenu: toggleMobileMenu,
                    }}
                    props={{ isMobileMenuOpen: isMobileMenuOpen }}
                />
            </div>
            <NavBarSport />
            <main className="relative">
                {isBasketOpen && (
                    <div className="w-full h-full absolute flex items-start justify-end bg-black bg-opacity-50 z-10">
                        <BasketSport closeBasket={toggleBasket} />
                    </div>
                )}
                {isFavoriteOpen && (
                    <div className="w-full h-full absolute flex items-start justify-end bg-black bg-opacity-50 z-10">
                        <FavoriteSport closeFavorite={toggleFavorite} />
                    </div>
                )}
                {isStreamsOpen && (
                    <div className="w-full h-full absolute flex items-start justify-center bg-black bg-opacity-50 z-10">
                        <StreamsSport closeBasket={toggleStreams} />
                    </div>
                )}
                {isMobileSearchOpen && (
                    <div className="w-full h-full absolute flex items-start justify-end bg-white z-10">
                        <MobileSearch />
                    </div>
                )}
                {isMobileMenuOpen && (
                    <div className="w-full h-full absolute flex items-start justify-end bg-white z-10">
                        <MobileMenu />
                    </div>
                )}
                <Outlet />
            </main>
            <FooterSport />
            {/* <FloatingIsland navMenuHeight={navMenuHeight} /> */}
        </>
    );
}

export default SportContainer;
