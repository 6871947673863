import { GlobeSVG } from "../../images/SVGAssets";

function LanguageSport(params) {

    return (
        <div className="flex px-[14px] py-[10px] gap-3 font-secondary text-sm not-italic font-medium leading-5 text-white">
            <p className="block xl:hidden">{params.short}</p>
            <p className="hidden xl:block">{params.name}</p>
            <GlobeSVG />
        </div>
    )
}

export default LanguageSport;