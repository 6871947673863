import { useNavigate } from "react-router-dom";
import ControlWithIconSport from "./ControlWithIconSport";
import SearchBarSport from "../Header/SearchBarSport";
import LanguageSport from "./LanguageSport";
import {
    MenuSVG,
    MenuSVG2,
    Search2SVG,
    Search3SVG,
    Eye2SVG,
    Eye3SVG,
    Heart2SVG,
    Heart4SVG,
    ShoppingBagSVG,
    ShoppingBag2SVG,
    XClose3SVG,
} from "../../images/SVGAssets";

function NavMenuSport(params) {
    const navigate = useNavigate();

    return (
        <>
            <div className="flex md:hidden items-center justify-between h-[60px] px-6 py-4 border-b border-gray-lidabro-border-3">
                <div onClick={params.actions.mobileMenu}>
                    {params.props.isMobileMenuOpen ? (
                        <XClose3SVG />
                    ) : (
                        <MenuSVG2 />
                    )}
                </div>
                <div onClick={() => navigate("/sport")}>
                    <h1 className="font-main text-xl text-gray-lidabro-breadcrumb font-bold leading-[10px] tracking-[-0.4px] cursor-pointer">
                        Lidabro
                    </h1>
                </div>
                <div onClick={params.actions.mobileSearch}>
                    <Search2SVG />
                </div>
                <div onClick={params.actions.streams}>
                    <Eye2SVG />
                </div>
                <div onClick={params.actions.favorite}>
                    <Heart2SVG />
                </div>
                <div onClick={params.actions.basket}>
                    <ShoppingBagSVG />
                </div>
            </div>

            <div className="hidden md:flex items-center justify-between px-6 py-4 bg-gray-lidabro-text">
                <div className="flex gap-6">
                    <div className="block lg:hidden">
                        <MenuSVG />
                    </div>
                    <h1
                        onClick={() => navigate("/sport")}
                        className="font-main text-[32px] not-italic font-bold leading-4 -tracking-[.64px] text-white cursor-pointer"
                    >
                        Lidabro
                    </h1>
                </div>
                <div className="hidden lg:flex w-1/2 gap-3">
                    <SearchBarSport placeholderText={"Find and item"} />
                    <LanguageSport name="English" short="En" />
                </div>
                <div className="flex items-center gap-6">
                    <div className="lg:hidden">
                        <ControlWithIconSport
                            name={"Search"}
                            icon={<Search3SVG />}
                        />
                    </div>
                    <ControlWithIconSport
                        name={"Streams"}
                        icon={<Eye3SVG />}
                        action={params.actions.streams}
                    />
                    <ControlWithIconSport
                        name={"Favorites"}
                        icon={<Heart4SVG />}
                        action={params.actions.favorite}
                    />
                    <ControlWithIconSport
                        name={"Basket"}
                        icon={<ShoppingBag2SVG />}
                        action={params.actions.basket}
                    />
                </div>
            </div>
        </>
    );
}

export default NavMenuSport;
